<template>
  <!-- 机组 -->
  <el-dialog
    title="添加控制规则"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    @closed="clearForm"
  >
    <!-- 日期 -->
    <div class="week_box">
      <el-checkbox v-model="weeks.week1">周一</el-checkbox>
      <el-checkbox v-model="weeks.week2">周二</el-checkbox>
      <el-checkbox v-model="weeks.week3">周三</el-checkbox>
      <el-checkbox v-model="weeks.week4">周四</el-checkbox>
      <el-checkbox v-model="weeks.week5">周五</el-checkbox>
      <el-checkbox v-model="weeks.week6">周六</el-checkbox>
      <el-checkbox v-model="weeks.week7">周日</el-checkbox>
    </div>

    <!-- 规则名称 -->
    <div class="rule_name">
      <div class="input_box">
        <div class="label">规则名称</div>
        <input type="text" v-model="ruleName" maxlength="10" />
      </div>
    </div>

    <!-- 产水时间 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_3.png" />
          <span>产水时间</span>
          <div class="star">*</div>
        </div>
      </div>
      <div class="content">
        <div class="select_box" v-for="item in waterProductionTime" :key="item.id">
          <div class="item">
            <el-checkbox
              v-model="item.selected"
              @change="selectedChange(item, 1)"
            ></el-checkbox>
            <span>{{ item.time_start }} - {{ item.time_end }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 产水目标温度 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_7.png" />
          <span>产水目标温度</span>
          <div class="star">*</div>
        </div>
        <div class="right_box">
          <el-select v-model="data_index">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <div class="select_box" v-for="item in waterProductionTemp" :key="item.id">
          <div class="item">
            <el-checkbox
              v-model="item.selected"
              @change="selectedChange(item, 3)"
            ></el-checkbox>
            <span>小于{{ item.min }}℃开启</span>
            <span>大于{{ item.max }}℃关闭</span>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="addControl">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetPreConfig, AddChannelTaskApi, GetWaterCase } from "@/api";

export default {
  data() {
    return {
      // 端口
      port: {},
      // 项目id
      id_project: "",
      // 设备号
      id_device: "",
      // 是否显示弹窗
      dialogVisible: false,
      weeks: {
        week1: true,
        week2: true,
        week3: true,
        week4: true,
        week5: true,
        week6: true,
        week7: true,
      },
      // 规则名称
      ruleName: "",
      // 产水时间
      waterProductionTime: [],
      // 产水目标温度
      waterProductionTemp: [],
      // 产水目标温度端口
      data_index: "",
      // 端口列表
      options: [],
    };
  },
  methods: {
    // 添加机组控制
    add(port, project) {
      this.port = port;
      this.id_project = project;
      this.id_device = port.id_device;
      this.getPreConfig();
      this.getWaterCaseList();
      this.dialogVisible = true;
    },

    // 获取预设配置
    async getPreConfig() {
      const params = {
        id_project: this.id_project,
      };
      const { ret, data, msg } = await GetPreConfig(params);
      if (ret !== 0) {
        return this.$message.error(msg || "获取预设参数失败!");
      }
      // 产水时间 water_production_time_array
      data.water_production_time_array.map((i) => {
        i.selected = false;
      });
      this.waterProductionTime = data.water_production_time_array;
      // 产水目标温度 water_production_temp_array
      data.water_production_temp_array.map((i) => (i.selected = false));
      this.waterProductionTemp = data.water_production_temp_array;
    },

    // 获取水箱
    async getWaterCaseList() {
      const params = {
        id_device: this.id_device,
      };
      const { ret, data, msg } = await GetWaterCase(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.options = data;
    },

    // 选中项发生变化
    selectedChange(item, type) {
      if (type === 1) {
        this.waterProductionTime.map((i) => {
          if (i.id !== item.id) {
            i.selected = false;
          }
        });
      } else {
        this.waterProductionTemp.map((i) => {
          if (i.id !== item.id) {
            i.selected = false;
          }
        });
      }
    },

    // 添加机组控制
    async addControl() {
      // 判断是否选择了星期
      let sum = 0;
      const arr = [0, 1, 2, 3, 4, 5, 6];
      arr.map((i) => {
        if (this.weeks["week" + (i + 1)]) {
          sum += Math.pow(2, i);
        }
      });
      if (sum === 0) {
        return this.$message.warning("请选择星期");
      }

      // 判断是否输入了规则名称
      const ruleName = this.ruleName.trim();
      if (!ruleName) {
        this.$message.warning("请输入规则名称");
        return;
      }

      // 产水时间
      const timeSelected = this.waterProductionTime.filter((i) => {
        return i.selected === true;
      });
      if (!timeSelected.length) {
        return this.$message.warning("请选择产水时间");
      }

      // 产水目标温度
      const tempSelected = this.waterProductionTemp.filter((i) => {
        return i.selected === true;
      });
      if (!tempSelected.length) {
        return this.$message.warning("请选择产水目标温度");
      }

      // 产水端口
      if (this.data_index === "") {
        return this.$message.warning("请选择水箱");
      }

      const params = {
        id_channel_control: this.port.id,
        week_day: sum,
        remark: ruleName,
        detail_array: [],
      };
      // 产水时间
      const index1 = this.waterProductionTime.findIndex((i) => i.selected);
      const current1 = this.waterProductionTime[index1];
      params.time_start = current1.time_start;
      params.time_end = current1.time_end;
      //  产水目标温度
      const index2 = this.waterProductionTemp.findIndex((i) => i.selected);
      const current2 = this.waterProductionTemp[index2];
      params.detail_array.push({
        id: this.data_index,
        cfg_type: 0,
        preset: current2.min,
        target: current2.max,
      });
      const { ret, data, msg } = await AddChannelTaskApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.$message.success("添加机组控制计划成功");
      this.dialogVisible = false;
      const port = { ...this.port };
      port.show = false;
      this.$emit("refresh", port);
    },

    // 清除选择
    clearForm() {
      this.ruleName = "";
      this.waterProductionTime = [];
      this.waterProductionTemp = [];
      this.data_index = "";
      Object.keys(this.weeks).map((key) => {
        this.weeks[key] = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.week_box {
  text-align: center;
}

.rule_name {
  display: flex;
  justify-content: center;
  margin: 18px 0;

  .input_box {
    width: 300px;
    display: flex;
    align-items: center;

    .label {
      position: relative;
      width: 90px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      text-align: right;
      padding-right: 12px;

      &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        content: "*";
        color: red;
        font-size: 14px;
      }
    }

    input {
      flex: 1;
      height: 32px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      outline: none;
      box-sizing: border-box;
      padding-left: 12px;
    }
  }
}

.item_box {
  border: 1px solid #f0f3f8;
  border-radius: 4px;
  margin: 12px 0;

  .title {
    height: 48px;
    background: #f0f3f8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;

    .left_box {
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        margin: 6px 12px 0 24px;
      }

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .star {
        color: #ff0000;
        font-size: 14px;
        margin-left: 4px;
      }
    }
  }

  .content {
    padding: 0 32px;

    .select_box {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(n + 2) {
        border-top: 1px solid #eee;
      }

      .item {
        width: 400px;
        height: 42px;
        display: flex;
        align-items: center;

        span {
          margin-left: 36px;
        }
      }
    }
  }
}
</style>
