<template>
  <!-- 补水 -->
  <el-dialog
    title="添加控制规则"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    @closed="clearForm"
  >
    <!-- 日期 -->
    <div class="week_box">
      <el-checkbox v-model="weeks.week1">周一</el-checkbox>
      <el-checkbox v-model="weeks.week2">周二</el-checkbox>
      <el-checkbox v-model="weeks.week3">周三</el-checkbox>
      <el-checkbox v-model="weeks.week4">周四</el-checkbox>
      <el-checkbox v-model="weeks.week5">周五</el-checkbox>
      <el-checkbox v-model="weeks.week6">周六</el-checkbox>
      <el-checkbox v-model="weeks.week7">周日</el-checkbox>
    </div>

    <!-- 规则名称 -->
    <div class="rule_name">
      <div class="input_box">
        <div class="label">规则名称</div>
        <input type="text" v-model="ruleName" maxlength="10" />
      </div>
    </div>

    <!-- 补水时间 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_5.png" />
          <span>补水时间</span>
          <div class="star">*</div>
        </div>
      </div>
      <div class="content">
        <div class="select_box" v-for="item in waterSupplementTime" :key="item.id">
          <div class="item">
            <el-checkbox
              v-model="item.selected"
              @change="selectedChange(item, 1)"
            ></el-checkbox>
            <span>{{ item.time_start }} - {{ item.time_end }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 目标水位 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_4.png" />
          <span>目标水位</span>
          <div class="star">*</div>
        </div>
        <div class="right_box">
          <el-select v-model="index1">
            <el-option
              v-for="item in waterBoxOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <div class="select_box" v-for="item in waterLevel" :key="item.id">
          <div class="item">
            <el-checkbox
              v-model="item.selected"
              @change="selectedChange(item, 2)"
            ></el-checkbox>
            <span>小于{{ item.min }}%开启</span>
            <span>大于{{ item.max }}%关闭</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 补水目标温度 -->
    <div class="item_box">
      <div class="title">
        <div class="left_box">
          <img src="../../../../../assets/pre_config_7.png" />
          <span>补水目标温度</span>
        </div>
        <div class="right_box">
          <el-select v-model="index2">
            <el-option
              v-for="item in waterBoxOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="content">
        <div class="select_box" v-for="item in waterSupplementTemp" :key="item.id">
          <div class="item">
            <el-checkbox
              v-model="item.selected"
              @change="selectedChange(item, 3)"
            ></el-checkbox>
            <span>小于{{ item.min }}℃关闭</span>
            <span>大于{{ item.max }}℃开启</span>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="addControl">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetPreConfig, AddChannelTaskApi, GetWaterCase } from "@/api";

export default {
  data() {
    return {
      // 端口信息
      port: {},
      // 项目id
      id_project: "",
      // 设备号
      id_device: "",
      // 是否显示弹窗
      dialogVisible: false,
      weeks: {
        week1: true,
        week2: true,
        week3: true,
        week4: true,
        week5: true,
        week6: true,
        week7: true,
      },
      // 规则名称
      ruleName: "",
      // 补水时间
      waterSupplementTime: [],
      // 目标水位
      waterLevel: [],
      // 水位索引列表
      waterBoxOptions: [],
      // 水位索引
      index1: "",
      // 补水目标温度
      waterSupplementTemp: [],
      // 温度索引
      index2: "",
    };
  },
  methods: {
    // 添加补水阀控制
    add(port, project) {
      console.log(port, project);
      this.port = port;
      this.id_project = project;
      this.id_device = port.id_device;
      this.getPreConfig();
      this.getWaterBox();
      this.dialogVisible = true;
    },

    // 获取预设配置
    async getPreConfig() {
      const params = {
        id_project: this.id_project,
      };
      const { ret, data, msg } = await GetPreConfig(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      // 补水时间 water_replenishment_time_array
      data.water_replenishment_time_array.map((i) => {
        i.selected = false;
      });
      this.waterSupplementTime = data.water_replenishment_time_array;
      // 目标水位 level_target_array
      data.level_target_array.map((i) => (i.selected = false));
      this.waterLevel = data.level_target_array;
      // 补水目标温度 water_replenishment_temp_array
      data.water_replenishment_temp_array.map((i) => (i.selected = false));
      this.waterSupplementTemp = data.water_replenishment_temp_array;
    },

    // 获取水箱
    async getWaterBox() {
      const params = {
        id_device: this.id_device,
      };
      const { ret, data, msg } = await GetWaterCase(params);
      if (ret) {
        return this.$message.error(msg);
      }
      this.waterBoxOptions = data;
      console.log(data);
    },

    // 选中项发生变化
    selectedChange(item, type) {
      if (type === 1) {
        this.waterSupplementTime.map((i) => {
          if (i.id !== item.id) {
            i.selected = false;
          }
        });
      } else if (type === 2) {
        this.waterLevel.map((i) => {
          if (i.id !== item.id) {
            i.selected = false;
          }
        });
      } else {
        this.waterSupplementTemp.map((i) => {
          if (i.id !== item.id) {
            i.selected = false;
          }
        });
      }
    },

    // 添加补水阀控制
    async addControl() {
      // 校验星期
      let sum = 0;
      const arr = [0, 1, 2, 3, 4, 5, 6];
      arr.map((i) => {
        if (this.weeks["week" + (i + 1)]) {
          sum += Math.pow(2, i);
        }
      });
      if (sum === 0) {
        return this.$message.warning("请选择星期");
      }
      // 校验名称
      const ruleName = this.ruleName.trim();
      if (!ruleName) {
        this.$message.warning("请输入规则名称");
        return;
      }
      // 产水时间
      const timeSelected = this.waterSupplementTime.filter((i) => {
        return i.selected === true;
      });
      if (!timeSelected.length) {
        return this.$message.warning("请选择补水时间");
      }

      // 目标水位
      const levelSelected = this.waterLevel.filter((i) => {
        return i.selected === true;
      });
      if (!levelSelected.length) {
        return this.$message.warning("请选择目标水位");
      }

      if (this.index1 === "") {
        return this.$message.warning("请选择水箱");
      }

      // 补水目标温度 (可选参数)
      const tempSelected = this.waterSupplementTemp.filter((i) => {
        return i.selected === true;
      });
      if (tempSelected.length && this.index2 === "") {
        return this.$message.warning("请选择水箱");
      }
      const params = {
        id_channel_control: this.port.id,
        week_day: sum,
        remark: ruleName,
        detail_array: [],
      };
      // 补水时间
      const selected1 = this.waterSupplementTime.findIndex((i) => i.selected);
      const current1 = this.waterSupplementTime[selected1];
      params.time_start = current1.time_start;
      params.time_end = current1.time_end;

      // 目标水位
      const selected2 = this.waterLevel.findIndex((i) => i.selected);
      const current2 = this.waterLevel[selected2];
      params.detail_array.push({
        id: this.index1,
        cfg_type: 1,
        preset: current2.min,
        target: current2.max,
      });

      // 补水目标温度
      if (tempSelected.length) {
        const selected3 = this.waterSupplementTemp.findIndex((i) => i.selected);
        const current3 = this.waterSupplementTemp[selected3];
        params.detail_array.push({
          id: this.index2,
          cfg_type: 0,
          preset: current3.min,
          target: current3.max,
        });
      }
      const { ret, data, msg } = await AddChannelTaskApi(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.$message.success("添加成功");
      this.dialogVisible = false;
      const port = { ...this.port };
      port.show = false;
      this.$emit("refresh", port);
    },

    // 清除选择
    clearForm() {
      this.ruleName = "";
      this.waterSupplementTime = [];
      this.waterLevel = [];
      this.index1 = "";
      this.waterSupplementTemp = [];
      this.index2 = "";
      Object.keys(this.weeks).map((key) => {
        this.weeks[key] = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.week_box {
  text-align: center;
}

.rule_name {
  display: flex;
  justify-content: center;
  margin: 18px 0;

  .input_box {
    width: 300px;
    display: flex;
    align-items: center;

    .label {
      position: relative;
      width: 90px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      text-align: right;
      padding-right: 12px;

      &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        content: "*";
        color: red;
        font-size: 14px;
      }
    }

    input {
      flex: 1;
      height: 32px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      outline: none;
      box-sizing: border-box;
      padding-left: 12px;
    }
  }
}

.item_box {
  border: 1px solid #f0f3f8;
  border-radius: 4px;
  margin: 12px 0;

  .title {
    height: 48px;
    background: #f0f3f8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;

    .left_box {
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        margin: 0 12px 0 24px;
      }

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .star {
        color: #ff0000;
        font-size: 14px;
        margin-left: 4px;
      }
    }
  }

  .content {
    padding: 0 32px;

    .select_box {
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(n + 2) {
        border-top: 1px solid #eee;
      }

      .item {
        width: 400px;
        height: 42px;
        display: flex;
        align-items: center;

        span {
          margin-left: 36px;
        }
      }
    }
  }
}

.msg {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f8d071;
  margin-right: 24px;
}
</style>
